.overlay[_ngcontent-ng-c489702081] {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: -40%;
  background: transparent;
  z-index: 99;
  pointer-events: auto;
}
.ngx-spinner-overlay[_ngcontent-ng-c4183080771] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ngx-spinner-overlay[_ngcontent-ng-c4183080771]
  > div[_ngcontent-ng-c4183080771]:not(.loading-text) {
  top: 50%;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translate(-50%, -50%);
}
.loading-text[_ngcontent-ng-c4183080771] {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
}
.app-body[_ngcontent-ng-c2875214609] {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}
.login-agbody[_ngcontent-ng-c2875214609] {
  background: var(--login-bg);
  min-height: 100vh;
}
.login-wrap[_ngcontent-ng-c2875214609],
.maintain-wrap[_ngcontent-ng-c2875214609] {
  position: absolute;
  top: 17%;
  left: calc(50% - 270px);
  width: 540px;
  height: 408px;
  border-radius: 8px;
  box-shadow: 0 5px 20px #00000080;
}
.login-agbody[_ngcontent-ng-c2875214609] .login-wrap[_ngcontent-ng-c2875214609],
.login-agbody[_ngcontent-ng-c2875214609]
  .maintain-wrap[_ngcontent-ng-c2875214609] {
  background: var(--login-bg);
}
.kv[_ngcontent-ng-c2875214609] {
  width: var(--kvWidth);
  height: 100%;
  border-radius: 8px 0 0 8px;
  float: left;
}
.login-agbody[_ngcontent-ng-c2875214609] .kv[_ngcontent-ng-c2875214609] {
  background: var(--loginSideImg);
}
.login-panel[_ngcontent-ng-c2875214609] {
  margin: 80px 0 0 25px;
  float: left;
}
.login-wrap[_ngcontent-ng-c2875214609]:after {
  content: "";
  display: block;
  clear: both;
}
.text-danger[_ngcontent-ng-c2875214609] {
  color: var(--login-error-msg) !important;
}
a.eyebtn[_ngcontent-ng-c2875214609] {
  color: var(--login-input-icon);
}
.login-panel[_ngcontent-ng-c2875214609] dt[_ngcontent-ng-c2875214609] {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  font-weight: 400;
  color: var(--loginTextPC);
}
.login-agbody[_ngcontent-ng-c2875214609]
  .login-panel[_ngcontent-ng-c2875214609] {
  color: #1e1e1e;
}
.login-panel[_ngcontent-ng-c2875214609] dd[_ngcontent-ng-c2875214609] {
  position: relative;
  width: 220px;
  margin-bottom: 7px;
  margin-inline-start: 0px;
}
.login-panel[_ngcontent-ng-c2875214609] input[_ngcontent-ng-c2875214609],
.login-panel[_ngcontent-ng-c2875214609] textarea[_ngcontent-ng-c2875214609] {
  width: 100%;
  height: 33px;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid var(--login-input-border);
  box-shadow: inset 0 2px #0000001a;
  margin: 0;
  font-family: Tahoma, Helvetica, sans-serif;
  color: var(--login-input-text);
  background: var(--login-input-bg);
  border-radius: 4px;
  padding: 5px;
  box-sizing: border-box;
  outline: 0;
}
.login-agbody[_ngcontent-ng-c2875214609]
  .login-panel[_ngcontent-ng-c2875214609]
  input[_ngcontent-ng-c2875214609],
.login-agbody[_ngcontent-ng-c2875214609]
  .login-panel[_ngcontent-ng-c2875214609]
  textarea[_ngcontent-ng-c2875214609] {
  border-color: #3e8142;
}
.login-agbody[_ngcontent-ng-c2875214609] .btn-send[_ngcontent-ng-c2875214609] {
  background: var(--login-page-btn-bg);
  border: unset;
  border-radius: 4px;
  color: var(--login-page-btn-text);
}
.login-panel[_ngcontent-ng-c2875214609] .btn-send[_ngcontent-ng-c2875214609] {
  width: 218px;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  font-weight: 700;
  display: block;
  text-align: center;
  margin: 15px 0 0;
}
.icon-login[_ngcontent-ng-c2875214609] {
  width: 10px;
  height: 11px;
}
.logo-main[_ngcontent-ng-c2875214609] {
  width: 160px;
  height: 68px;
}
@media only screen and (max-width: 767px) {
  .login-agbody[_ngcontent-ng-c2875214609]
    .login-wrap[_ngcontent-ng-c2875214609],
  .login-agbody[_ngcontent-ng-c2875214609]
    .maintain-wrap[_ngcontent-ng-c2875214609] {
    background: var(--login-bg);
    left: 10px;
    width: 94%;
  }
  .login-agbody[_ngcontent-ng-c2875214609] .kv[_ngcontent-ng-c2875214609] {
    display: none;
  }
  .login-agbody[_ngcontent-ng-c2875214609]
    .login-panel[_ngcontent-ng-c2875214609] {
    width: 100%;
  }
  .login-panel[_ngcontent-ng-c2875214609] {
    margin: 25px 0;
  }
  .logo-main-div[_ngcontent-ng-c2875214609] {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
  .logo-main-div[_ngcontent-ng-c2875214609] img[_ngcontent-ng-c2875214609] {
    width: 250px;
  }
  .login-panel[_ngcontent-ng-c2875214609] dt[_ngcontent-ng-c2875214609] {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: var(--mblLoginTextcolor);
    margin-bottom: 25px;
  }
  .login-panel[_ngcontent-ng-c2875214609] dd[_ngcontent-ng-c2875214609] {
    width: 90%;
    margin-left: 18px;
    margin-bottom: 10px;
  }
  .login-panel[_ngcontent-ng-c2875214609] input[_ngcontent-ng-c2875214609],
  .login-panel[_ngcontent-ng-c2875214609] textarea[_ngcontent-ng-c2875214609] {
    height: 45px;
    font-size: 18px;
    padding: 10px;
  }
  .login-panel[_ngcontent-ng-c2875214609] .btn-send[_ngcontent-ng-c2875214609] {
    width: 100%;
    height: 45px;
    font-size: 18px;
  }
}
input[_ngcontent-ng-c2875214609]:-webkit-autofill,
input[_ngcontent-ng-c2875214609]:-webkit-autofill:hover,
input[_ngcontent-ng-c2875214609]:-webkit-autofill:focus,
input[_ngcontent-ng-c2875214609]:-webkit-autofill:active {
  -webkit-background-clip: text;
  background: var(--login-input-bg);
  color: var(--login-input-text);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #ffffff29;
}
[_ngcontent-ng-c2875214609]::-webkit-input-placeholder {
  color: var(--login-input-placeholder) !important;
}
@media screen and (min-width: 768px) {
  .logo-main[_ngcontent-ng-c2875214609] {
    width: 220px;
    margin-bottom: 15px;
  }
}

:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}
:root {
  --loginBGColor: linear-gradient(#a4dc60 0%, #4f9f21 100%);
  --loginButtonBG: linear-gradient(-180deg, #f72424 0%, #bb1c00 100%);
  --headerTopBG: linear-gradient(-180deg, #315195 0%, #14213d 100%);
  --menuColor: linear-gradient(#a4dc60 0%, #4f9f21 100%);
  --menuTextColor: #000000;
  --menuBorderColor: rgba(9, 68, 16, 0.3);
  --modal-header-bg: linear-gradient(-180deg, #315195 0%, #14213d 100%);
  --reports-submit-btn-bg: linear-gradient(-180deg, #315195 0%, #14213d 100%);
  --btnColor: linear-gradient(-180deg, #315195 0%, #14213d 100%);
  --newsColor: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
  --warning: undefined;
  --primary: undefined;
  --loginSideImg: unset;
  --kvWidth: 140px;
  --secondaryBtnBg: linear-gradient(-180deg, #b8beca 0%, #989595 100%);
  --modal-header-text: #ffffff;
  --mblLoginTextcolor: #ffffff;
  --loginMobileBGColor: #3e3c3c;
  --refereshIconPS: -80px -1475px;
  --loginTextPC: #000000;
  --loginBackgroundImg: #14213d
    url(https://ag.t90exchange.com/bg-login.a83e090a3860efd6efa4.jpg) no-repeat
    center 4px;
  --mainBodyBG: #ededed;
  --header-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --header-text-color: white;
  --header-exposure-color: red;
  --bets-btn-color: white;
  --bets-btn-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --bets-btn-border-color: #0b1933;
  --bets-btn-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
  --refresh-btn-color: white;
  --refresh-btn-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --refresh-btn-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
  --refresh-btn-border-color: #0b1933;
  --settings-btn-color: white;
  --settings-btn-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --settings-btn-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.4);
  --settings-btn-border-color: #0b1933;
  --login-btn-bg: linear-gradient(-180deg, #f72424 0%, #bb1c00 100%);
  --login-btn-color: white;
  --desktop-account-btn-bg: linear-gradient(
    180deg,
    #2e2e2e,
    #282828 42%,
    #2e2e2e
  );
  --desktop-account-text-color: white;
  --desktop-account-icons-color: #fd8f3b;
  --exchange-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --exchange-text-color: white;
  --search-input-bg: white;
  --search-input-border-color: black;
  --search-input-text-color: black;
  --search-input-icons-color: black;
  --search-box-bg: white;
  --search-box-border: #ccc;
  --search-box-text-color: black;
  --search-box-bottom-border-color: black;
  --login-bg: linear-gradient(#535353 0%, #000000 80%);
  --login-cross-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --login-cross-color: black;
  --login-input-bg: white;
  --login-input-text: black;
  --login-input-icon: black;
  --login-input-placeholder: grey;
  --login-input-border: #fd8f3b;
  --login-error-msg: white;
  --login-page-btn-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --login-page-btn-text: white;
  --myaccount-bg: white;
  --myaccount-box-shadow: rgba(0, 0, 0, 0.5);
  --myaccount-username-bg: #e4e7ea;
  --myaccount-username-text: black;
  --myaccount-username-border: ;
  --myaccount-text: black;
  --myaccount-border: #c8ced3;
  --myaccount-logout-btn-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --myaccount-logout-btn-text: white;
  --age-modal-bg: linear-gradient(#535353 0%, #000000 80%);
  --age-modal-text: #ffffff;
  --age-modal-cross-bg: #fd8f3b;
  --age-modal-cross-text: #000000;
  --age-modal-lines: #ffffff;
  --age-modal-confirm-btn-bg: #ffffff;
  --age-modal-confirm-btn-text: #000000;
  --age-modal-cancel-btn-bg: #000000;
  --age-modal-cancel-btn-text: #ffffff;
  --age-modal-btn-border: #ffffff;
  --modal-header-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --modal-header-text: #ffffff;
  --modal-bg: #ffffff;
  --modal-text: #23282c;
  --modal-line: #cccccc;
  --modal-ok-bg: white;
  --modal-ok-text: black;
  --modal-ok-border: grey;
  --modal-heading-bg: #eee;
  --modal-heading-text: #243a48;
  --news-bar-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --news-text-bg: linear-gradient(-180deg, #274253 0%, #0f191f 82%);
  --news-text-color: white;
  --news-text-icon-color: #ffffff;
  --news-marque-text-color: #ffffff;
  --sportsbar-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --sportsbar-text-color: black;
  --sportsbar-border-color: #797777;
  --sportsbar-active-color: white;
  --mbl-exchange-bar-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --mbl-exchange-bar-text-color: #ffffff;
  --mbl-exchange-bar-border-color: #ffffff;
  --mbl-exchange-bar-active-border: #ffffff;
  --mbl-sports-bar-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --mbl-sports-text-color: black;
  --mbl-sports-active-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --mbl-sports-active-text-color: white;
  --magnifier-bg: linear-gradient(#525252 0%, #2d2d2d 80%);
  --magnifier-color: white;
  --highlight-tile-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --highlgiht-tile-text-color: #000000;
  --desktop-highlights-sport-bar-bg: linear-gradient(
    -180deg,
    #fd3523 0%,
    #fd8f3b 100%
  );
  --desktop-sports-tile-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --desktop-sports-tile-text-color: #ffffff;
  --desktop-sports-tile-active-bg: #dddcd6;
  --desktop-sports-tile-active-text-color: #000000;
  --markets-bg: linear-gradient(-180deg, #ffffff 0%, #ffffff 100%);
  --markets-text-color: #2789ce;
  --markets-dateTime-color: #777777;
  --markets-pin-color: #7e97a7;
  --markets-clock-icon-color: #008000;
  --markets-border-bottom: #c8ced3;
  --markets-pin-color-active: #6bbd0f;
  --markets-virtual-icon: #000000;
  --desktop-1x2-bg: #dddcd6;
  --desktop-1x2-text-color: #000000;
  --desktop-1x2-bottom-border-color: #dddcd6;
  --hosrseRacing-schedule-bg: linear-gradient(
    -180deg,
    #000000 0%,
    #000000 100%
  );
  --hosrseRacing-schedule-color: #ffffff;
  --horseRacing-country-active-bg: #ffffff;
  --horseRacing-country-active-border: #c8ced3;
  --horseRacing-country-color: #000000;
  --horseRacing-country-active-color: #000000;
  --country-ui-bg: #e2e2e2;
  --country-list-border: #c8ced3;
  --country-event-box-bg: #e2e2e2;
  --country-event-box-color: #000000;
  --country-event-color: #000000;
  --casino-container-bg: linear-gradient(180deg, #ffffff, #ffffff 42%);
  --casino-container-border-bottom: #7e97a7;
  --casino-tab-bg: linear-gradient(180deg, #000000, #000000 42%);
  --casino-tab-color: #ffffff;
  --casino-tab-active-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --casino-tab-active-color: #000000;
  --casino-icon-bg: linear-gradient(180deg, #000000, #000000 42%);
  --casino-bottom-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --casino-bottom-color: #000000;
  --reports-filters-box-bg: #e0e6e6;
  --reports-filters-box-border: #000501;
  --reports-submit-btn-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --reports-submit-btn-clr: #ffffff;
  --reports-submit-btn-border: #cccccc;
  --reports-title-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --reports-title-text: #ffffff;
  --reports-table-head-bg: #e0e6e6;
  --reports-table-head-text: #000000;
  --child-navigation-link: #2789ce;
  --side-nav-top-bar-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --side-nav-link-border: #d2d6e2;
  --side-nav-top-text-color: #ffffff;
  --side-nav-bg: linear-gradient(-180deg, #ffffff 0%, #ffffff 100%);
  --side-nav-text-color: #223869;
  --side-nav-icon-color: #83ae17;
  --side-nav-hover-bg: #e6efd1;
  --side-nav-hover-text-color: #83ae17;
  --right-sidenav-bg: #ffffff;
  --right-sidenav-title-bar-bg: linear-gradient(
    -180deg,
    #000000 0%,
    #000000 100%
  );
  --right-sidenav-title-bar-color: #ffffff;
  --inplay-tabs-border: #3b5160;
  --inplay-tabs-bg: #ffffff;
  --inplay-tabs-text: #3b5160;
  --inplay-tabs-active-bg: #3b5160;
  --inplay-tabs-active-text: #ffffff;
  --inplay-sports-tile-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --inplay-sports-tile-text: #ffffff;
  --inplay-1x2-bg: #dddcd6;
  --inplay-1x2-text: #000000;
  --settings-bar-bg: linear-gradient(-180deg, #2e4b5e 0, #243a48 82%);
  --settings-bar-color: #ffffff;
  --stack-border-color: conic-gradient(
      #6ca7ec,
      #2222b9,
      #6ca7ec,
      #2222b9,
      #6ca7ec,
      #2222b9,
      #6ca7ec
    )
    1;
  --stack-heading-color: #243a48;
  --setting-save-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --setting-save-color: #ffffff;
  --mbl-sportName-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --mbl-sportName-text: #ffffff;
  --mbl-sportName-tv: #ffffff;
  --mbl-tvScore-tab-bg: linear-gradient(180deg, #303030, #303030 42%);
  --mbl-tvScore-tab-text: #ffffff;
  --mb-tvScore-border: #666060;
  --mbl-tvScore-active-border: #ffffff;
  --mbl-filter-background: linear-gradient(-180deg, #e0e6e6 0%, #e0e6e6 100%);
  --mbl-filter-tab-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --mbl-filter-tab-text: #ffffff;
  --mbl-filter-tab-border: #000000;
  --mbl-filter-tab-active-bg: linear-gradient(
    -180deg,
    #fd8f3b 0%,
    #fd3523 100%
  );
  --mbl-filter-tab-active-text: #000000;
  --mbl-filter-tab-active-border: #000000;
  --market-border: #c8ced3;
  --marketdetails-marketName-bg: linear-gradient(
    -180deg,
    #2e4b5e 0%,
    #243a48 82%
  );
  --marketdetails-marketName-text: #ffffff;
  --marketdetails-info-icon: #ffffff;
  --cashout-icon-bg: #ffb900;
  --cashout-icon-color: #000000;
  --after-bet-cashout-bg: #ccc;
  --marketdetails-marketname-bar-bg: #ffffff;
  --marketdetails-marketname-bar-text: #000000;
  --marketName-bar-bottom-border: #7e97a7;
  --jockey-tags-bg: #bed5d8;
  --jockey-tags-text: #000000;
  --marketdetails-matchodds-Box-bg: linear-gradient(
    180deg,
    #ffffff,
    #ffffff 42%
  );
  --matchodds-runner-text: #23282c;
  --matchodds-pl-minus: #d0021b;
  --matchodds-pl-plus: #228b22;
  --marketdetails-matchodds-bottom-border: #7e97a7;
  --matchodds-minmax-bg: #bed5d8;
  --matchodds-minmax-text: #315195;
  --matchodds-minmax-value: #000000;
  --matchodds-suspended-bg: #fff;
  --matchodds-suspended-color: #ca1010;
  --matchodds-suspended-border: #ca1010;
  --bookmaker-bg: #faf8d8;
  --bookmaker-back-lay-color: #000000;
  --bookmaker-runners-text: #23282c;
  --bookmaker-pl-minus: #d0021b;
  --bookmaker-pl-plus: #228b22;
  --bookmaker-bottom-border: #7e97a7;
  --bookmaker-suspended-bg: rgba(36, 58, 72, 0.4);
  --bookmaker-suspended-color: #ffffff;
  --bookmaker-news-text: #333333;
  --markets-fancy-icon-bg: linear-gradient(180deg, #0a92a5 15%, #076875 100%);
  --markets-fancy-text: #ffffff;
  --markets-fancy-info: #ffffff;
  --fancy-info-icon-bg: linear-gradient(180deg, #1cb0b5 15%, #1cb0b5 100%);
  --fancy-category-active-bg: #ffffff;
  --fancy-category-active-text: #000000;
  --fancy-category-bg: rgba(255, 255, 255, 0.5);
  --fancy-category-text: #000000;
  --fancy-market-bg: #ffffff;
  --fancy-runner-text: #23282c;
  --fancy-runner-bottom-border: #7e97a7;
  --fancy-minmax-text-color: #577c94;
  --fancy-minmax-value: #000000;
  --fancy-mbl-minmax-bg: #ffffff;
  --fancy-minmax-border: #e0e6e6;
  --fancy-mbl-minmax-cross: #1e1e1e;
  --fancy-book-btn-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --fancy-book-btn-text: #ffffff;
  --fancy-pl-minus: #d0021b;
  --facny-pl-plus: #228b22;
  --facny-suspended-bg: rgba(36, 58, 72, 0.4);
  --facny-suspended-color: #ffffff;
  --fancy-news-bg: #c2d5e4;
  --fancy-news-text: #3b5160;
  --markets-sportsbook-bg: linear-gradient(180deg, #f26d1c 15%, #d14100 100%);
  --markets-sportsbook-text: #ffffff;
  --markets-sportsbook-info: #ffffff;
  --sportsbook-info-icon-bg: linear-gradient(180deg, #f58b30 15%, #f58b30 100%);
  --sportsbook-category-active-bg: #ffffff;
  --sportsbook-category-active-text: #000000;
  --sportsbook-category-bg: rgb(255, 255, 255, 50%);
  --sportsbook-category-text: #000000;
  --sportmarket-border: #c8ced3;
  --sportbook-market-bg: #ffffff;
  --sportbook-runner-text: #23282c;
  --sportbook-runner-border: #7e97a7;
  --sportbook-pl-minus: #d0021b;
  --sportbook-pl-plus: #228b22;
  --stack-cancel-bg: #f9f9f9;
  --stack-cancel-border-bg: #333;
  --stack-cancel-color: #000000;
  --stack-placebet-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --stack-placebet-border-bg: #000000;
  --stack-placebet-color: #ffffff;
  --stack-bg: #f9f9f9;
  --stack-border-bg: #333;
  --stack-color: #000000;
  --plusMinus-bg: linear-gradient(-180deg, #fff 0, #eee 89%);
  --plusMinus-color: #1f72ac;
  --plusMinus-border-color: #aaaaaa;
  --input-stake-bg: #ffffff;
  --input-stake-color: #1e1e1e;
  --input-disable-stake-color: "black";
  --sports-quicklinks-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --sports-quicklinks-text: #ffffff;
  --sport-linkstab-bg: #14213d;
  --sport-linkstab-border: #4b4b4b;
  --sport-link-text: #ffffff;
  --sport-link-icons: #ffffff;
  --sport-allsports-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --sport-allsports-text: #ffffff;
  --sport-list-bg: #ffffff;
  --sport-list-text: #2789ce;
  --sport-list-icon-color: #1e1e1e;
  --sport-list-icon-bg: #ffffff;
  --sport-list-border: #e0e6e6;
  --account-title-bg: linear-gradient(-180deg, #2e4b5e 0%, #243a48 82%);
  --account-title-text: #ffffff;
  --account-links-bg: #ffffff;
  --account-links-text: #2789ce;
  --account-link-border: #e0e6e6;
  --acoount-arrow-bg: #ffffff;
  --account-arrow-text: #1e1e1e;
  --account-arrow-border: #1e1e1e;
  --account-logout-bg: linear-gradient(-180deg, #e93522 0, #be2414 100%);
  --account-logout-text: #ffffff;
  --account-logout-border: #8a0011;
  --loader1-bg: linear-gradient(180deg, #2a3a43 27%, #1c282d 83%);
  --loader2-bg: linear-gradient(-180deg, #fd8f3b 0%, #fd3523 100%);
  --loader-body: #ffffff;
  --loader-text: black;
  --footer-border-top: "";
  --footer-bg: #ededed;
  --footer-text-color: #000000;
  --footer-support-text-color: #000000;
  --footer-whatsapp-color: #28d146;
  --footer-phone-color: black;
  --footer-telegram-color: #0088cc;
  --bottom-nav-bg: linear-gradient(-180deg, #243a48 20%, #172732 91%);
  --bottom-nav-text: #ffffff;
  --bottom-nav-icon: black;
  --bottom-nav-active-icon: #ffffff;
  --bottom-nav-active-text: #ffffff;
  --bottom-nav-active-bg: linear-gradient(-180deg, #32617f 20%, #1f4258 91%);
  --market-details-cmp-back-gradient-color: #59708d;
  --market-details-cmp-lay_1-back-1-a-background: #72bbef;
  --market-details-cmp-lay-gradient-bk-image: linear-gradient(
    270deg,
    rgba(231, 170, 184, 0.15) 5%,
    rgba(231, 170, 184, 0.8) 60%
  );
  --market-details-cmp-back-gradient-background: linear-gradient(
    90deg,
    rgba(130, 183, 221, 0.15) 0,
    rgba(130, 183, 221, 0.8) 65%
  );
  --market-details-cmp-lay-gradient-lay_1-a-background: #faa9ba;
  --market-details-cmp-back-1-background: #72bbef;
  --market-details-cmp-back-1-border-l: #fff;
  --market-details-cmp-lay-1-background: #faa9ba;
  --market-details-cmp-odd-row-back-1-active-background: #1a8ee1;
  --market-details-cmp-odd-row-back-1-active-color: #fff;
  --market-details-cmp-odd-row-lay-1-active-background: #f4496d;
  --market-details-cmp-odd-row-lay-1-active-color: #fff;
}
:root{
  --mainBodyBG: #ededed;
  --header-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --header-text-color: white;
  --header-exposure-color: red;
  --bets-btn-color: white;
  --bets-btn-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --bets-btn-border-color: #0B1933;
  --bets-btn-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, .4);
  --refresh-btn-color: white;
  --refresh-btn-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --refresh-btn-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, .4);
  --refresh-btn-border-color: #0B1933;
  --settings-btn-color: white;
  --settings-btn-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --settings-btn-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, .4);
  --settings-btn-border-color: #0B1933;
  --login-btn-bg: linear-gradient(-180deg, #f72424 0%, #bb1c00 100%);
  --login-btn-color: white;
  --desktop-account-btn-bg: linear-gradient(180deg, #2e2e2e, #282828 42%, #2e2e2e);
  --desktop-account-text-color: white;
  --desktop-account-icons-color: #fd8f3b;
  --exchange-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --exchange-text-color: white;
  --search-input-bg: white;
  --search-input-border-color: black;
  --search-input-text-color: black;
  --search-input-icons-color: black;
  --search-box-bg: white;
  --search-box-border: #ccc;
  --search-box-text-color: black;
  --search-box-bottom-border-color: black;
  --login-bg: linear-gradient(#535353 0%, #000000 80%);
  --login-cross-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --login-cross-color: black;
  --login-input-bg: white;
  --login-input-text: black;
  --login-input-icon: black;
  --login-input-placeholder: grey;
  --login-input-border: #fd8f3b;
  --login-error-msg: white;
  --login-page-btn-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --login-page-btn-text: white;
  --myaccount-bg: white;
  --myaccount-box-shadow: rgba(0, 0, 0, .5);
  --myaccount-username-bg: #e4e7ea;
  --myaccount-username-text: black;
  --myaccount-username-border: #ccc;
  --myaccount-text: black;
  --myaccount-border: #c8ced3;
  --myaccount-logout-btn-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --myaccount-logout-btn-text: white;
  --age-modal-bg: linear-gradient(#535353 0%, #000000 80%);
  --age-modal-text: #FFFFFF;
  --age-modal-cross-bg: #fd8f3b;
  --age-modal-cross-text: #000000;
  --age-modal-lines: #FFFFFF;
  --age-modal-confirm-btn-bg: #FFFFFF;
  --age-modal-confirm-btn-text: #000000;
  --age-modal-cancel-btn-bg: #000000;
  --age-modal-cancel-btn-text: #FFFFFF;
  --age-modal-btn-border: #FFFFFF;
  --modal-header-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --modal-header-text: #FFFFFF;
  --modal-bg: #FFFFFF;
  --modal-text: #23282c;
  --modal-line: #cccccc;
  --modal-ok-bg: white;
  --modal-ok-text: black;
  --modal-ok-border: grey;
  --modal-heading-bg: #eee;
  --modal-heading-text: #243a48;
  --news-bar-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --news-text-bg: linear-gradient(-180deg, #274253 0%, #0f191f 82%);
  --news-text-color: white;
  --news-text-icon-color: #FFFFFF;
  --news-marque-text-color: #FFFFFF;
  --sportsbar-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --sportsbar-text-color: black;
  --sportsbar-border-color: #797777;
  --sportsbar-active-color: white;
  --mbl-exchange-bar-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --mbl-exchange-bar-text-color: #ffffff;
  --mbl-exchange-bar-border-color: #ffffff;
  --mbl-exchange-bar-active-border: #ffffff;
  --mbl-sports-bar-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --mbl-sports-text-color: black;
  --mbl-sports-active-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --mbl-sports-active-text-color: white;
  --magnifier-bg: linear-gradient(#525252 0%, #2d2d2d 80%);
  --magnifier-color: white;
  --highlight-tile-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --highlgiht-tile-text-color: #000000;
  --desktop-highlights-sport-bar-bg: linear-gradient(-180deg, #fd3523 0%, #fd8f3b 100%);
  --desktop-sports-tile-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --desktop-sports-tile-text-color: #ffffff;
  --desktop-sports-tile-active-bg: #dddcd6;
  --desktop-sports-tile-active-text-color: #000000;
  --markets-bg: linear-gradient(-180deg, #ffffff 0%, #ffffff 100%);
  --markets-text-color: #2789ce;
  --markets-dateTime-color: #777777;
  --markets-pin-color: #7e97a7;
  --markets-clock-icon-color: #008000;
  --markets-border-bottom: #c8ced3;
  --markets-pin-color-active: #6bbd0f;
  --markets-virtual-icon: #000000;
  --desktop-1x2-bg: #dddcd6;
  --desktop-1x2-text-color: #000000;
  --desktop-1x2-bottom-border-color: #dddcd6;
  --hosrseRacing-schedule-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --hosrseRacing-schedule-color: #ffffff;
  --horseRacing-country-active-bg: #ffffff;
  --horseRacing-country-active-border: #c8ced3;
  --horseRacing-country-color: #000000;
  --horseRacing-country-active-color: #000000;
  --country-ui-bg: #e2e2e2;
  --country-list-border: #c8ced3;
  --country-event-box-bg: #e2e2e2;
  --country-event-box-color: #000000;
  --country-event-color: #000000;
  --casino-container-bg: linear-gradient(180deg, #ffffff, #ffffff 42%);
  --casino-container-border-bottom: #7e97a7;
  --casino-tab-bg: linear-gradient(180deg, #000000, #000000 42%);
  --casino-tab-color: #ffffff;
  --casino-tab-active-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --casino-tab-active-color: #000000;
  --casino-icon-bg: linear-gradient(180deg, #000000, #000000 42%);
  --casino-bottom-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --casino-bottom-color: #000000;
  --reports-filters-box-bg: #e0e6e6;
  --reports-filters-box-border: #000501;
  --reports-submit-btn-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --reports-submit-btn-clr: #ffffff;
  --reports-submit-btn-border: #cccccc;
  --reports-title-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --reports-title-text: #ffffff;
  --reports-table-head-bg: #e0e6e6;
  --reports-table-head-text: #000000;
  --child-navigation-link: #2789ce;
  --side-nav-top-bar-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --side-nav-link-border: #d2d6e2;
  --side-nav-top-text-color: #FFFFFF;
  --side-nav-bg: linear-gradient(-180deg, #FFFFFF 0%, #FFFFFF 100%);
  --side-nav-text-color: #223869;
  --side-nav-icon-color: #83AE17;
  --side-nav-hover-bg: #E6EFD1;
  --side-nav-hover-text-color: #83AE17;
  --right-sidenav-bg: #FFFFFF;
  --right-sidenav-title-bar-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --right-sidenav-title-bar-color: #FFFFFF;
  --inplay-tabs-border: #3B5160;
  --inplay-tabs-bg: #FFFFFF;
  --inplay-tabs-text: #3B5160;
  --inplay-tabs-active-bg: #3B5160;
  --inplay-tabs-active-text: #FFFFFF;
  --inplay-sports-tile-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --inplay-sports-tile-text: #FFFFFF;
  --inplay-1x2-bg: #DDDCD6;
  --inplay-1x2-text: #000000;
  --settings-bar-bg: linear-gradient(-180deg, #2e4b5e 0, #243a48 82%);
  --settings-bar-color: #FFFFFF;
  --stack-border-color: conic-gradient(#6ca7ec, #2222b9, #6ca7ec, #2222b9, #6ca7ec, #2222b9, #6ca7ec) 1;
  --stack-heading-color: #243a48;
  --setting-save-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --setting-save-color: #FFFFFF;
  --mbl-sportName-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --mbl-sportName-text: #FFFFFF;
  --mbl-sportName-tv: #FFFFFF;
  --mbl-tvScore-tab-bg: linear-gradient(180deg, #303030, #303030 42%);
  --mbl-tvScore-tab-text: #FFFFFF;
  --mb-tvScore-border: #666060;
  --mbl-tvScore-active-border: #FFFFFF;
  --mbl-filter-background: linear-gradient(-180deg, #e0e6e6 0%, #e0e6e6 100%);
  --mbl-filter-tab-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --mbl-filter-tab-text: #FFFFFF;
  --mbl-filter-tab-border: #000000;
  --mbl-filter-tab-active-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --mbl-filter-tab-active-text: #000000;
  --mbl-filter-tab-active-border: #000000;
  --market-border: #c8ced3;
  --marketdetails-marketName-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --marketdetails-marketName-text: #FFFFFF;
  --marketdetails-info-icon: #FFFFFF;
  --cashout-icon-bg: #FFB900;
  --cashout-icon-color: #000000;
  --after-bet-cashout-bg: #ccc;
  --marketdetails-marketname-bar-bg: #FFFFFF;
  --marketdetails-marketname-bar-text: #000000;
  --marketName-bar-bottom-border: #7e97a7;
  --jockey-tags-bg: #bed5d8;
  --jockey-tags-text: #000000;
  --marketdetails-matchodds-Box-bg: linear-gradient(180deg, #ffffff, #ffffff 42%);
  --matchodds-runner-text: #23282c;
  --matchodds-pl-minus: #d0021b;
  --matchodds-pl-plus: #228b22;
  --marketdetails-matchodds-bottom-border: #7e97a7;
  --matchodds-minmax-bg: #bed5d8;
  --matchodds-minmax-text: #315195;
  --matchodds-minmax-value: #000000;
  --matchodds-suspended-bg: #fff;
  --matchodds-suspended-color: #ca1010;
  --matchodds-suspended-border: #ca1010;
  --bookmaker-bg: #FAF8D8;
  --bookmaker-back-lay-color: #000000;
  --bookmaker-runners-text: #23282C;
  --bookmaker-pl-minus: #d0021b;
  --bookmaker-pl-plus: #228b22;
  --bookmaker-bottom-border: #7e97a7;
  --bookmaker-suspended-bg: rgba(36, 58, 72, .4);
  --bookmaker-suspended-color: #FFFFFF;
  --bookmaker-news-text: #333333;
  --markets-fancy-icon-bg: linear-gradient(180deg, #0a92a5 15%, #076875 100%);
  --markets-fancy-text: #FFFFFF;
  --markets-fancy-info: #ffffff;
  --fancy-info-icon-bg: linear-gradient(180deg, #1cb0b5 15%, #1cb0b5 100%);
  --fancy-category-active-bg: #ffffff;
  --fancy-category-active-text: #000000;
  --fancy-category-bg: rgba(255, 255, 255, .5);
  --fancy-category-text: #000000;
  --fancy-market-bg: #ffffff;
  --fancy-runner-text: #23282C;
  --fancy-runner-bottom-border: #7e97a7;
  --fancy-minmax-text-color: #577c94;
  --fancy-minmax-value: #000000;
  --fancy-mbl-minmax-bg: #ffffff;
  --fancy-minmax-border: #e0e6e6;
  --fancy-mbl-minmax-cross: #1e1e1e;
  --fancy-book-btn-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --fancy-book-btn-text: #ffffff;
  --fancy-pl-minus: #d0021b;
  --facny-pl-plus: #228b22;
  --facny-suspended-bg: rgba(36, 58, 72, .4);
  --facny-suspended-color: #ffffff;
  --fancy-news-bg: #c2d5e4;
  --fancy-news-text: #3b5160;
  --markets-sportsbook-bg: linear-gradient(180deg, #f26d1c 15%, #d14100 100%);
  --markets-sportsbook-text: #ffffff;
  --markets-sportsbook-info: #ffffff;
  --sportsbook-info-icon-bg: linear-gradient(180deg, #f58b30 15%, #f58b30 100%);
  --sportsbook-category-active-bg: #ffffff;
  --sportsbook-category-active-text: #000000;
  --sportsbook-category-bg: rgb(255, 255, 255, 50%);
  --sportsbook-category-text: #000000;
  --sportmarket-border: #c8ced3;
  --sportbook-market-bg: #ffffff;
  --sportbook-runner-text: #23282C;
  --sportbook-runner-border: #7e97a7;
  --sportbook-pl-minus: #d0021b;
  --sportbook-pl-plus: #228b22;
  --stack-cancel-bg: #f9f9f9;
  --stack-cancel-border-bg: #333;
  --stack-cancel-color: #000000;
  --stack-placebet-bg: linear-gradient(-180deg, #000000 0%, #000000 100%);
  --stack-placebet-border-bg: #000000;
  --stack-placebet-color: #ffffff;
  --stack-bg: #f9f9f9;
  --stack-border-bg: #333;
  --stack-color: #000000;
  --plusMinus-bg: linear-gradient(-180deg, #fff 0, #eee 89%);
  --plusMinus-color: #1f72ac;
  --plusMinus-border-color: #aaaaaa;
  --input-stake-bg: #ffffff;
  --input-stake-color: #1e1e1e;
  --input-disable-stake-color: black;
  --sports-quicklinks-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --sports-quicklinks-text: #ffffff;
  --sport-linkstab-bg: #14213d;
  --sport-linkstab-border: #4b4b4b;
  --sport-link-text: #ffffff;
  --sport-link-icons: #ffffff;
  --sport-allsports-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --sport-allsports-text: #ffffff;
  --sport-list-bg: #ffffff;
  --sport-list-text: #2789ce;
  --sport-list-icon-color: #1e1e1e;
  --sport-list-icon-bg: #ffffff;
  --sport-list-border: #e0e6e6;
  --account-title-bg: linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%);
  --account-title-text: #ffffff;
  --account-links-bg: #ffffff;
  --account-links-text: #2789ce;
  --account-link-border: #e0e6e6;
  --acoount-arrow-bg: #ffffff;
  --account-arrow-text: #1e1e1e;
  --account-arrow-border: #1e1e1e;
  --account-logout-bg: linear-gradient(-180deg, #e93522 0, #be2414 100%);
  --account-logout-text: #ffffff;
  --account-logout-border: #8a0011;
  --loader1-bg: linear-gradient(180deg, #000000 27%, #000000 83%);
  --loader2-bg: linear-gradient(-180deg, #F6A21E 0%, #F6A21E 100%);
  --loader-body: #ffffff;
  --loader-text: black;
  --footer-border-top: rgba(0, 0, 0, .3);
  --footer-text-color: #000000;
  --footer-support-text-color: #000000;
  --footer-whatsapp-color: #28D146;
  --footer-phone-color: black;
  --footer-telegram-color: #0088cc;
  --bottom-nav-bg: linear-gradient(-180deg, #243a48 20%, #172732 91%);
  --bottom-nav-text: #ffffff;
  --bottom-nav-icon: black;
  --bottom-nav-active-icon: #ffffff;
  --bottom-nav-active-text: #ffffff;
  --bottom-nav-active-bg: linear-gradient(-180deg, #32617f 20%, #1f4258 91%);
}
body {
  margin: 0;
  font-family: Tahoma, Helvetica, sans-serif !important;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.5;
  color: #23282c;
  text-align: left;
  background: var(--mainBodyBG);
}

.card[_ngcontent-ng-c2958549179]{position:relative;display:flex;flex-direction:column;min-width:0;word-wrap:break-word;background:#fff;background-clip:initial;border:1px solid rgba(0,0,0,.125);border-radius:.25rem}.game-heading[_ngcontent-ng-c2958549179]{background:var(--reports-title-bg);color:var(--reports-title-text);font-weight:700;font-size:15px;padding:5px 10px}.sidebar-account[_ngcontent-ng-c2958549179]   a[_ngcontent-ng-c2958549179]{display:block;color:#000;border-bottom:1px solid #ccc;padding:5px 10px;text-decoration:none}.sidebar-account[_ngcontent-ng-c2958549179]   a.active[_ngcontent-ng-c2958549179]{background-color:#d1ddef;color:#1e1e1e}.sidebar-account[_ngcontent-ng-c2958549179]   a[_ngcontent-ng-c2958549179]:hover:not(.active){background-color:var(--side-nav-hover-bg)}.sidebar-account[_ngcontent-ng-c2958549179]{margin:0;padding:0;width:100%;background-color:#fff;height:100%;overflow:auto;color:#1e1e1e}.paddingDektop[_ngcontent-ng-c2958549179]{padding-left:10px;padding-right:10px}@media screen and (max-width: 700px){.sidebar-account[_ngcontent-ng-c2958549179]{width:100%;height:auto;position:relative}.sidebar-account[_ngcontent-ng-c2958549179]   a[_ngcontent-ng-c2958549179]{float:left}.paddingmobile[_ngcontent-ng-c2958549179]{padding-left:0;padding-right:0}}@media screen and (max-width: 500px){.sidebar-account[_ngcontent-ng-c2958549179]   a[_ngcontent-ng-c2958549179]{text-align:center;float:none}}